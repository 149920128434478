<template>
  <div class="box">
    <div>
      专题：<span
        :class="{ active: indexgrade == index }"
        v-for="(item, index) in typelist"
        :key="index"
        @click="getTypeOne(item, index)"
      >
        {{ item.special_name }}
      </span>
    </div>
    <div>
      分类：<span
        :class="{ active: indexsid == index }"
        v-for="(item, index) in sublist"
        :key="index"
        @click="getclassOne(item, index)"
        >{{ item.special_name }}</span
      >
    </div>
    <div style="margin-top: 5px; padding-bottom: 0">
      课程：
      <div style="margin-top: 5px; padding-left: 42px; padding-bottom: 0">
        <span
          style="
            display: inline-block;
            margin-bottom: 10px;
          "
          :class="{ active: indexAct == index }"
          v-for="(item, index) in booklist"
          :key="index"
          @click="getcourseOne(item, index)"
          >
           {{ item.special_name }}
         
          </span
        >
        <span v-if="!booklist.length">暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import URL from "../../api/index";
import eventBus from "@/api/eventBus";
export default {
  data() {
    return {
      typelist: [],
      sublist: [],
      booklist: [],
      indexAct: 0,
      indexgrade: 0,
      indexsid: 0,
    };
  },
  components: {},
  mounted() {
    this.getType();
    this.getclassify("XKZT");
    this.getcourse("XKZT01");
  },
  methods: {
    getType() {
      this.$post(URL.getStype).then((res) => {
        this.typelist = res.result;
      });
    },
    getclassify(id) {
      var data = { special_parentid: id };
      this.$post(URL.getSclassify, data).then((res) => {
        this.sublist = res.result;
        this.getcourse(res.result[0].special_id);
      });
    },
    getcourse(id) {
      var data = { special_parentid: id };
      this.$post(URL.getScourse, data).then((res) => {
        // console.log(res);
        if (res.result.length) {
          this.booklist = res.result;
          if (res.result[0] && res.result[0].special_id) {
            eventBus.$emit("specialid", res.result[0].special_id);
          }
        } else {
          this.booklist = [];
          eventBus.$emit("specialid", "0");
        }
      });
    },

    // 一级
    getTypeOne(res, index) {
      this.getclassify(res.special_id);
      this.indexAct = 0;
      this.indexsid = 0;
      this.indexgrade = index;
    },
    // 选择 二级
    getclassOne(res, index) {
      // console.log(res);
      this.getcourse(res.special_id);
      this.indexAct = 0;
      this.indexsid = index;
    },
    // 三级
    getcourseOne(res, index) {
      this.indexAct = index;
      eventBus.$emit("specialid", res.special_id);
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px 20px 15px;
  margin-top: 30px;
  border-radius: 16px;
  box-sizing: border-box;
  div {
    font-size: 14px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
    padding-bottom: 15px;
    span {
      margin: 0 20px;
      cursor: pointer;
      padding: 4px 14px;
    }
  }
  div:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
}
.active {
  background: #2c84ff;
  color: #fff;
  border-radius: 14px;
  padding: 4px 14px;
}
</style>
