<template>
  <div>
    <tabtop />
    <coursenum />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import tabtop from "@/components/topiccourse/tabtop";
import coursenum from "@/components/topiccourse/coursenum";
export default {
  data() {
    return {};
  },
  components: { tabtop, coursenum,Footer },
  mounted() {},
  methods: {},
};
</script>

<style lang='less'>
</style>
