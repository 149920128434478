<template>
  <div class="box">
    <div class="clearfix">
      <div
        class="content"
        v-for="item in booklist"
        :key="item.course_id"
        @click="getDetails(item)"
      >
        <img
          src="../../assets/image/course.jpg"
          style="border-radius: 16px 16px 0 0; width: 264px"
          alt=""
        />
        <div class="contentbtm">
          <div class="title">{{ item.course_name }}</div>
          <div class="tips">
            <span
              v-if="item.course_free == '0'"
              class="fl"
              style="
                background-color: #ffca45;
                padding: 0 10px;
                color: #fff;
                border-radius: 6px;
              "
              >VIP</span
            >
            <span
              v-else
              class="fl"
              style="
                background-color: #57da67;
                padding: 0 10px;
                color: #fff;
                border-radius: 6px;
              "
              >可试听</span
            >
            <span class="fr" style="color: #87898d"
              >{{ item.course_views }}人观看</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="!booklist.length"
        style="background-color: #fff; border-radius: 14px; min-height: 476px"
      >
        <el-empty :image-size="220"></el-empty>
      </div>
    </div>
    <pagination />
    <!-- 激活弹框 -->
    <el-dialog
      title="会员激活"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      style="margin-top: 25vh"
    >
      <div style="text-align: center">当前会员未激活，请先激活会员</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          size="small"
          @click="centerDialogVisible = false"
          style="padding: 9px 25px"
          >取 消</el-button
        >
        <el-button round size="small" type="danger" @click="getPay(1)"
          >学习卡激活</el-button
        >
        <el-button round size="small" type="primary" @click="getPay(2)"
          >充值中心</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import URL from "../../api/index";
import eventBus from "@/api/eventBus";
import pagination from "@/components/synchronous/pagination";
export default {
  data() {
    return {
      centerDialogVisible: false,
      booklist: [],
      count: 0,
      chapterid: "",
      curPage: 1,
    };
  },
  components: { pagination },
  mounted() {
    this.getcourseList("XKZT0101", 1);
    eventBus.$on("specialid", (res) => {
      if (res == "0") {
        this.booklist = [];
        eventBus.$emit("count", {
          count: 0,
          curPage: 1,
          pagesize: 12,
        });
      } else {
        this.specialid = res;
        this.getcourseList(res, 1);
      }
    });
    eventBus.$on("pages", (res) => {
      this.getcourseList(this.specialid, res);
    });
  },
  methods: {
    // 课程列表
    getcourseList(id, page) {
      var data = { special_id: id, page, pagenum: 12 };
      this.$post(URL.getSlist, data).then((res) => {
        if (res.result) {
          this.booklist = res.result.data;
          eventBus.$emit("count", {
            count: res.result.count,
            curPage: page,
            pagesize: 12,
          });
        }
      });
    },
    getDetails(item) {
      if (item.course_free == "0") {
        // vip
        if (localStorage.getItem("loginInfo")) {
          if (localStorage.getItem("vip") == 1) {
            // this.$router.push(`/topiccourse/topicdetails?id=${item.course_id}`);
            // 打开新页面
            const {href} = this.$router.resolve({path:`/topiccourse/topicdetails`,query:{id:`${item.course_id}`}})
            window.open(href,"_blank")
          } else {
            // 不是vip
            this.centerDialogVisible = true;
          }
        } else {
          this.$confirm("此操作需要先进行登录~", "提示", {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/login");
            })
            .catch(() => {});
        }
      } else {
        // 可试听
        // this.$router.push(`/topiccourse/topicdetails?id=${item.course_id}`);
        const {href} = this.$router.resolve({path:`/topiccourse/topicdetails`,query:{id:`${item.course_id}`}})
        window.open(href,"_blank")
      }
    },
    getPay(id) {
      this.centerDialogVisible = false;
      if (id == 1) {
        this.$router.push("/cardActivation");
      } else {
        this.$router.push("/voucherCenter");
      }
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}
.content {
  cursor: pointer;
  float: left;
  margin: 0 18px 34px;
  width: 264px;
  height: 244px;
  background: #ffffff;
  opacity: 1;
  border-radius: 16px;
  .contentbtm {
    padding: 5px 15px;
    .title {
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
    }
  }

  .tips {
    font-size: 14px;
  }
}
</style>
