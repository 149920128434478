<template>
  <div class="box">
    <div class="block">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :hide-on-single-page="value"
        :page-size="pagesize"
        layout="prev, pager, next, jumper"
        :total="count"
        prev-text="上一页"
        next-text="下一页"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import eventBus from "@/api/eventBus";
export default {
  data() {
    return {
      pagesize: 0,
      count: 0,
      currentPage: 1,
      value: true,
    };
  },
  components: {},
  mounted() {
    eventBus.$on("count", (res) => {
      this.count = res.count;
      this.currentPage = res.curPage;
      this.pagesize = res.pagesize;
      if (res.count == 0) {
        this.value = true;
      } else {
        this.value = false;
      }
    });
  },
  methods: {
    handleCurrentChange(val) {
      eventBus.$emit("pages", val);
    },
  },
};
</script>

<style lang='less' scoped>
.box {
  text-align: center;
  padding: 50px 0 80px;
  // background-color: #f5f5f5 !important;
}
</style>
